// 跟消息相关的接口
import request from '../../request'

export function fetchList(params) {
    return request({
        url: '/office-service/invMaterial/list',
        params,
    })
}

export function add(data) {
    return request({
        url: '/office-service/invMaterial/add',
        data,
        method: "post",
    })
}

export function edit(data) {
    return request({
        url: '/office-service/invMaterial/update',
        data,
        method: "post",
    })
}

// 调整库存
export function adjust(data) {
    return request({
        url: '/office-service/invMaterial/adjust',
        data,
        method: "post",
    })
}

// 申请物料
export function apply(data) {
    return request({
        url: '/office-service/invMaterialApply/apply',
        data,
        method: "post"
    })
}

// 申领台账
export function fetchApplyList(data) {
    return request({
        url: '/office-service/invMaterialApply/standingBook',
        data,
        method: 'post'
    })
}
export function fetchApplyDeptList(data) {
    return request({
        url: '/office-service/invMaterialApply/standingBookDept',
        data,
        method: 'post'
    })
}

export function fetchApplyDetail(data) {
    return request({
        url: `/office-service/invMaterialApply/query/${data.id}`,
        method: "post"
    })
}

export function reapply(data) {
    return request({
        url: '/office-service/invMaterialApply/reApply',
        data,
        method: 'post'
    })
}